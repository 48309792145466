var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("a-input", {
          staticClass: "w-200 ml-5 mb-5",
          attrs: { allowClear: "", placeholder: "产品NO" },
          model: {
            value: _vm.params.productNo,
            callback: function($$v) {
              _vm.$set(_vm.params, "productNo", $$v)
            },
            expression: "params.productNo"
          }
        }),
        _c(
          "a-select",
          {
            staticClass: "w-200 ml-5 mb-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "举报类型" },
            model: {
              value: _vm.params.shamType,
              callback: function($$v) {
                _vm.$set(_vm.params, "shamType", $$v)
              },
              expression: "params.shamType"
            }
          },
          _vm._l(_vm.reportTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "w-200 ml-5 mb-5",
            attrs: {
              allowClear: "",
              showSearch: "",
              placeholder: "审核结果类型"
            },
            model: {
              value: _vm.params.handleStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "handleStatus", $$v)
              },
              expression: "params.handleStatus"
            }
          },
          _vm._l(_vm.reportCheckResultTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-button",
          { attrs: { type: "primary" }, on: { click: _vm.search } },
          [_vm._v("搜索")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("TableList", {
          attrs: { list: _vm.data, pagination: _vm.pagination },
          on: {
            changePage: _vm.handleChangePage,
            success: _vm.getList,
            watchSuccess: _vm.getWatchList
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }