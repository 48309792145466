import {objArrMapObj} from "@/untils/otherEvent"

/** 举报列表 后台：列表展示字段：举报人、举报的拍品ID标题、图片、截拍时间、举报时间、确认假币并去下架、不是假币、不确定。*/
export const reportAuctionTableColumns = [
    { title: "拍品名称", align: "center", scopedSlots: {customRender: 'itemProductNameSlot'} },
    { title: "拍品图片", align: "center", scopedSlots: { customRender: "itemAuctionImageSlot" }, width: 250 },
    { title: "举报信息", align: "center", scopedSlots: {customRender: 'itemReportUserInfoSlot'}, width: 250 },
    { title: "举报图片", align: "center", scopedSlots: { customRender: "itemReportAuctionImageSlot" }, width: 250 },
    { title: "审核状态", align: "center", scopedSlots: { customRender: "itemCheckAuctionTypeSlot" }, width: 150 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]

/** 举报类型  假币类型 1 批量假  10 同模假  20 真盒假币 30 工艺不对  40 其它*/
export const REPORT_TYPE_LIST = [
    { name: '批量作假', value: 1 },
    { name: '同模假', value: 10 },
    { name: '真盒假币', value: 20 },
    { name: '工艺不对', value: 30 },
    { name: '其他', value: 40 },
]

/** 举报类型对应的样式类 */
export const REPORT_TYPE_CLASS_TYPE = {
    1: 'color-red-zu-1',
    10: 'color-red-zu-3',
    20: 'color-red-zu-5',
    30: 'color-orange-red',
    40: 'color-gray',
}

/** 举报拍品审核结果类型 确认假币并去下架、不是假币、不确定。*/
export const REPORT_AUCTION_CHECK_RESULT_TYPE_LIST = [
    { name: '待处理', value: 0 },
    { name: '确认假币', value: 3 },
    { name: '不是假币', value: 6 },
    { name: '不确定', value: 9 },
]

/** 举报审核结果对应样式类 */
export const REPORT_AUCTION_CHECK_RESULT_CLASS_TYPE = {
    0: 'color-black',
    3: 'color-red',
    6: 'color-green',
    9: 'color-gray'
}

export const reportTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', REPORT_TYPE_LIST)[value]
}

export const reportAuctionCheckResultTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', REPORT_AUCTION_CHECK_RESULT_TYPE_LIST)[value]
}