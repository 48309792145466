import instance from "@/axios"

/** 获取用户举报拍品列表 */
export const getUserReportProductList = async (params) => {
    return instance({
        url: '/dq_admin/productUserReport/list',
        method: 'GET',
        params: params
    })
}

/** 确定举报状态 */
export const postConfirmReportCheckResult = async (params) => {
    return instance({
        url: '/dq_admin/productUserReport/handle',
        method: 'POST',
        data: params
    })
}